import domReady from '@wordpress/dom-ready';

domReady(() => {
  const buttons = document.querySelectorAll('.wp-element-button');

  if (0 < buttons.length) {
    buttons.forEach((button) => {
      const span = document.createElement('span');
      const text = button.textContent;
      span.textContent = text;
      button.textContent = '';
      button.appendChild(span);
    });
  }
});
