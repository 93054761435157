import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const alertsBar = document.querySelector('#alerts-bar');
  const alertsCloseBtn = document.querySelectorAll('.alertsBarCloseBtn');
  const slidesContainer = document.querySelector(
    '.alerts-bar__carousel .swiper-wrapper'
  );
  let alertSwiper = null;

  alertsBar.classList.add('js-enabled');

  const checkSlider = () => {
    const slides = document.querySelectorAll('.alerts-bar__item');
    let loop = true;

    if (0 < slides.length) {
      if (1 === slides.length) {
        loop = false;
      }
      if (null === alertSwiper) {
        createSlider(loop);
      }
      alertsBar.classList.add('visible');
    }
  };

  const addSlideToSwiper = (alert) => {
    const alertItem = document.createElement('div');
    alertItem.classList.add('alerts-bar__item');
    alertItem.classList.add('swiper-slide');

    let alertLink;

    if (alert.alert_icon) {
      const alertIcon = document.createElement('i');
      alertIcon.classList.add('alerts-bar__icon');
      alertIcon.classList.add('icon-' + alert.alert_icon);
      alertItem.appendChild(alertIcon);
    }

    if (alert.alert_link) {
      alertLink = document.createElement('a');
      alertLink.classList.add('alerts-bar__text__link');
      alertLink.setAttribute('href', alert.alert_link.url);
      alertItem.appendChild(alertLink);
    }

    if (alert.alert_text) {
      const alertText = document.createElement('div');
      alertText.classList.add('alerts-bar__text');
      // Ajouter le texte dans un paragraphe
      const alertTextParagraph = document.createElement('p');
      alertTextParagraph.innerHTML = alert.alert_text;
      alertText.appendChild(alertTextParagraph);

      if (alert.alert_link) {
        const alertLinkText = document.createElement('p');
        alertLinkText.classList.add('sr-only');
        alertLinkText.innerHTML = alert.alert_link.title;
        alertLink.appendChild(alertText);
        alertLink.appendChild(alertLinkText);
      } else {
        alertItem.appendChild(alertText);
      }
    }

    // Ajouter la diapositive au carrousel avec Swiper
    slidesContainer.prepend(alertItem);
  };

  const createSlider = (loopActivated) => {
    const args = {
      slidesPerView: 1,
      autoplay: {
        delay: 5000,
      },
      loop: loopActivated,
      pagination: {
        el: '.alerts-bar__pagination',
        clickable: true,
      },
    };
    alertSwiper = new Swiper('.alerts-bar__carousel', args);
  };

  fetch('/wp-json/alerts/v1/main')
    .then((response) => response.json())
    .then((data) => {
      if (data.length > 0 && '0' !== data) {
        data.forEach((alert) => {
          addSlideToSwiper(alert);
        });
      }
      checkSlider();
    })
    .catch((error) => {
      console.error('Error:', error);
    });

  if (alertsCloseBtn.length > 0) {
    alertsCloseBtn.forEach((closeBtn) => {
      closeBtn.addEventListener('click', () => {
        if (alertSwiper !== null) {
          alertSwiper.destroy();
        }
        alertsBar.classList.remove('visible');
        document.cookie = 'remove_alerts_bar=true';
      });
    });
  }
});
